import {
  Box,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  FormControl,
  MenuItem,
  Popper,
  Select,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import LineGraph from 'components/LineGraph/LineGraph';
import parse from 'html-react-parser';
import {
  Add,
  Check,
  CheckCircle,
  Close,
  HelpOutline,
  Sync,
  Troubleshoot,
  Warning,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import getAllDealNames from 'redux/actions/Common/getAllDealNames';
import crmHubspotSync from 'redux/actions/Common/crmHubspotSync';
import {
  artifactProcessingStatuses,
  artifactStatusMap,
  artifactTypesWithoutMedia,
} from 'data';
import { capitalizeText } from 'utils';
import generateNBA from 'redux/actions/Common/generateNBA';
import getArtifactRisks from 'redux/actions/Common/getArtifactRisks';

const NextBestActions = ({ readOnly, activeArtifact, ...rest }) => {
  const theme = useTheme();
  const history = useHistory();
  const pathParams = useParams();
  const dispatch = useDispatch();
  const teamId = localStorage.getItem('teamId') || pathParams?.teamId;

  const { userDetails } = useSelector((state) => state.userDetails);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [nextBestActions, setNextBestActions] = useState([]);
  const [activeNBA, setActiveNBA] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [hints, setHints] = useState([]);

  const [anchorElDetails, setAnchorElDetails] = useState(null);
  const [popperDetails, setPopperDetails] = useState(null);

  useEffect(() => {
    if (
      activeNBA &&
      Object?.values(activeNBA?.actions || {}) &&
      Object?.values(activeNBA?.actions || {})?.length > 0
    ) {
      setHints(
        Object?.values(activeNBA?.actions || {})?.reduce((acc, curr) => {
          acc.push(...(curr?.hints || []));
          return acc;
        }, []),
      );
      setQuestions(
        Object?.values(activeNBA?.actions || {})?.reduce((acc, curr) => {
          acc.push(...(curr?.questions || []));
          return acc;
        }, []),
      );
    }
  }, [activeNBA]);

  useEffect(() => {
    if (activeArtifact) {
      setNextBestActions(
        Object.values(activeArtifact?.nextBestActionsMap || {}),
      );
      setActiveNBA(
        Object.values(activeArtifact?.nextBestActionsMap || {})?.[0] || null,
      );
    }
  }, [activeArtifact]);

  console.log(hints);
  console.log(questions);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2} {...rest}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          border: '1px solid #d3d3d3',
          borderRadius: 2,
        }}
      >
        {activeArtifact?.nextBestActionsMap &&
        activeArtifact?.nextBestActionsMap?.length > 0 ? (
          <>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                // width: 1,
                paddingBottom: 10,
                minHeight: 300,
                maxHeight: 350,
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                  // outline: '1px solid slategrey',
                },
                padding: readOnly ? 2 : 1,
                // border: readOnly ? 'none' : '1px solid #d3d3d3',
                borderRadius: 2,
              }}
            >
              <Typography color={'text.secondary'} fontWeight={'bold'}>
                Next Best Actions
              </Typography>
              {nextBestActions?.map((action, index) => (
                <Box
                  key={index + 1}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: readOnly ? 'default' : 'pointer',
                    borderRadius: 2,
                    width: 1,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveNBA(action);
                  }}
                >
                  <Box
                    sx={{
                      background:
                        activeNBA?.id === action?.id ? '#C2DBFF' : '#F2F6FC',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 1,
                      flex: 1,
                      borderRadius: 2,
                      // minHeight: 80,
                      // border: dq?.pinned
                      //   ? '1px solid #beb9e7'
                      //   : 'none',
                      position: 'relative',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        flex: 1,
                        borderRadius: 2,
                        paddingLeft: 2,
                      }}
                    >
                      {/* <Typography
                                                              sx={{
                                                                // background: '#d3e2fe',
                                                                padding: 2,
                                                                borderTopLeftRadius: 8,
                                                                borderBottomLeftRadius: 8,
                                                                minHeight: 80,
                                                                minWidth: 80,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                fontWeight: 'bold',
                                                                cursor: 'pointer',
                                                              }}
                                                              onMouseEnter={(e) => {
                                                                setAnchorElDetails(
                                                                  e.currentTarget,
                                                                );
                                                                setPopperDetails(
                                                                  'This is a long explanation of why this question makes sense. It can be a multi line explanation. And the help box should have the height and width to handle that.',
                                                                );
                                                              }}
                                                              onMouseLeave={() => {
                                                                setAnchorElDetails(null);
                                                                setPopperDetails(null);
                                                              }}
                                                            >
                                                              {index < 9 ? 0 : ''}
                                                              {index + 1}
                                                            </Typography>
                                                            <Popper
                                                              id="mouse-over-popover"
                                                              sx={{
                                                                pointerEvents: 'none',
                                                                zIndex: 1111111,
                                                              }}
                                                              open={Boolean(
                                                                anchorElDetails,
                                                              )}
                                                              anchorEl={anchorElDetails}
                                                              placement="top"
                                                              onClose={() => {
                                                                setAnchorElDetails(null);
                                                              }}
                                                              // disableRestoreFocus
                                                            >
                                                              <Box
                                                                sx={{
                                                                  border:
                                                                    '1px solid #d3d3d3',
                                                                  borderRadius: 1,
                                                                  padding: 1,
                                                                  background: '#fff',
                                                                  maxWidth: 400,
                                                                  display: 'flex',
                                                                  flexDirection: 'column',
                                                                  gap: 1,
                                                                  // boxShadow: theme.shadows[1],
                                                                }}
                                                              >
                                                                <Typography
                                                                  variant={'body1'}
                                                                  color={'text.secondary'}
                                                                  sx={{
                                                                    padding: 1,
                                                                  }}
                                                                >
                                                                  {popperDetails || ''}
                                                                </Typography>
                                                              </Box>
                                                            </Popper> */}
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{
                          width: 1,
                          padding: 1,
                          justifyContent: 'space-between',
                        }}
                        onMouseEnter={(e) => {
                          setAnchorElDetails(e.currentTarget);
                          setPopperDetails(action?.risksAddressed);
                        }}
                        onMouseLeave={() => {
                          setAnchorElDetails(null);
                          setPopperDetails(null);
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              activeNBA?.id === action?.id
                                ? theme.palette.primary.main
                                : theme.palette.text.primary,
                          }}
                        >
                          {action?.action}
                        </Typography>
                        {/* <Typography color={'text.secondary'} variant="subtitle2">
                            {action?.explanation}
                          </Typography> */}
                      </Box>
                      <Popper
                        id="mouse-over-popover"
                        sx={{
                          pointerEvents: 'none',
                          zIndex: 1111111,
                        }}
                        open={Boolean(anchorElDetails)}
                        anchorEl={anchorElDetails}
                        placement="right"
                        onClose={() => {
                          setAnchorElDetails(null);
                        }}
                        // disableRestoreFocus
                      >
                        <Box
                          sx={{
                            border: '1px solid #d3d3d3',
                            borderRadius: 1,
                            padding: 1,
                            background: '#fff',
                            maxWidth: 400,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            // boxShadow: theme.shadows[1],
                          }}
                        >
                          <Typography>
                            Risks adressed by this action:
                          </Typography>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            gap={1}
                            flexWrap={'wrap'}
                          >
                            {popperDetails?.map((risk) => (
                              <Chip
                                key={risk?.id}
                                label={risk?.riskTitle}
                                size="small"
                                color="default"
                              />
                            ))}
                          </Box>
                          {popperDetails?.map((risk) => (
                            <Typography
                              key={risk?.id}
                              variant={'body1'}
                              color={'text.secondary'}
                              sx={{
                                padding: 1,
                              }}
                            >
                              {risk?.explanation}
                            </Typography>
                          ))}
                        </Box>
                      </Popper>
                    </Box>
                    {readOnly ? null : (
                      <Box
                        sx={{
                          // background: '#d3e2fe',
                          padding: 2,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 1,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          minHeight: 80,
                          position: 'relative',
                        }}
                      >
                        {/* <HelpOutline
                                                                  sx={{
                                                                    cursor: 'pointer',
                                                                  }}
                                                                  onMouseEnter={(e) => {
                                                                    setAnchorElDetails(
                                                                      e.currentTarget,
                                                                    );
                                                                    setPopperDetails(
                                                                      'This is a long explanation of why this question makes sense. It can be a multi line explanation. And the help box should have the height and width to handle that.',
                                                                    );
                                                                  }}
                                                                  onMouseLeave={() => {
                                                                    setAnchorElDetails(
                                                                      null,
                                                                    );
                                                                    setPopperDetails(
                                                                      null,
                                                                    );
                                                                  }}
                                                                />
                                                                <Popper
                                                                  id="mouse-over-popover"
                                                                  sx={{
                                                                    pointerEvents: 'none',
                                                                    zIndex: 1111111,
                                                                  }}
                                                                  open={Boolean(
                                                                    anchorElDetails,
                                                                  )}
                                                                  anchorEl={
                                                                    anchorElDetails
                                                                  }
                                                                  placement="left"
                                                                  onClose={() => {
                                                                    setAnchorElDetails(
                                                                      null,
                                                                    );
                                                                  }}
                                                                  // disableRestoreFocus
                                                                >
                                                                  <Box
                                                                    sx={{
                                                                      border:
                                                                        '1px solid #d3d3d3',
                                                                      borderRadius: 1,
                                                                      padding: 1,
                                                                      background: '#fff',
                                                                      maxWidth: 400,
                                                                      display: 'flex',
                                                                      flexDirection:
                                                                        'column',
                                                                      gap: 1,
                                                                      // boxShadow: theme.shadows[1],
                                                                    }}
                                                                  >
                                                                    <Typography
                                                                      variant={'body1'}
                                                                      color={
                                                                        'text.secondary'
                                                                      }
                                                                      sx={{
                                                                        padding: 1,
                                                                      }}
                                                                    >
                                                                      {popperDetails ||
                                                                        ''}
                                                                    </Typography>
                                                                  </Box>
                                                                </Popper> */}
                        {/* <Tooltip arrow placement="top" title="Edit">
                                                <img
                                                  src={'/dh-icons/edit.svg'}
                                                  style={{
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                  }}
                                                />
                                              </Tooltip> */}
                        {/* <Tooltip
                            arrow
                            placement="top"
                            title={
                              selectedRisks?.includes(dr?.id) ? 'Unselect' : 'Select'
                            }
                          >
                            {selectedRisks?.includes(dr?.id) ? (
                              <img
                                src={'/dh-icons/pinned.svg'}
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={(e) => {}}
                              />
                            ) : (
                              <img
                                src={'/dh-icons/unpinned.svg'}
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={(e) => {}}
                              />
                            )}
                          </Tooltip> */}
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                // width: 1,
                paddingBottom: 10,
                minHeight: 300,
                maxHeight: 350,
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                  // outline: '1px solid slategrey',
                },
                padding: readOnly ? 2 : 1,
                // border: readOnly ? 'none' : '1px solid #d3d3d3',
                borderRadius: 2,
              }}
            >
              <Typography color={'text.secondary'} fontWeight={'bold'} mb={1}>
                Details
              </Typography>
              {questions?.map((question, index) => (
                <Box
                  key={index + 1}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: readOnly ? 'default' : 'pointer',
                    borderRadius: 2,
                    width: 1,
                    gap: 1,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    // setActiveNBA(action);
                  }}
                >
                  <img
                    src="/dh-icons/question.svg"
                    width={'20px'}
                    height={'20px'}
                  />
                  <Box
                    sx={{
                      background: '#F2F6FC',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 1,
                      flex: 1,
                      borderRadius: 2,
                      // minHeight: 80,
                      // border: dq?.pinned
                      //   ? '1px solid #beb9e7'
                      //   : 'none',
                      position: 'relative',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        flex: 1,
                        borderRadius: 2,
                        paddingLeft: 2,
                      }}
                    >
                      {/* <Typography
                                                        sx={{
                                                          // background: '#d3e2fe',
                                                          padding: 2,
                                                          borderTopLeftRadius: 8,
                                                          borderBottomLeftRadius: 8,
                                                          minHeight: 80,
                                                          minWidth: 80,
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          justifyContent: 'center',
                                                          fontWeight: 'bold',
                                                          cursor: 'pointer',
                                                        }}
                                                        onMouseEnter={(e) => {
                                                          setAnchorElDetails(
                                                            e.currentTarget,
                                                          );
                                                          setPopperDetails(
                                                            'This is a long explanation of why this question makes sense. It can be a multi line explanation. And the help box should have the height and width to handle that.',
                                                          );
                                                        }}
                                                        onMouseLeave={() => {
                                                          setAnchorElDetails(null);
                                                          setPopperDetails(null);
                                                        }}
                                                      >
                                                        {index < 9 ? 0 : ''}
                                                        {index + 1}
                                                      </Typography>
                                                      <Popper
                                                        id="mouse-over-popover"
                                                        sx={{
                                                          pointerEvents: 'none',
                                                          zIndex: 1111111,
                                                        }}
                                                        open={Boolean(
                                                          anchorElDetails,
                                                        )}
                                                        anchorEl={anchorElDetails}
                                                        placement="top"
                                                        onClose={() => {
                                                          setAnchorElDetails(null);
                                                        }}
                                                        // disableRestoreFocus
                                                      >
                                                        <Box
                                                          sx={{
                                                            border:
                                                              '1px solid #d3d3d3',
                                                            borderRadius: 1,
                                                            padding: 1,
                                                            background: '#fff',
                                                            maxWidth: 400,
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: 1,
                                                            // boxShadow: theme.shadows[1],
                                                          }}
                                                        >
                                                          <Typography
                                                            variant={'body1'}
                                                            color={'text.secondary'}
                                                            sx={{
                                                              padding: 1,
                                                            }}
                                                          >
                                                            {popperDetails || ''}
                                                          </Typography>
                                                        </Box>
                                                      </Popper> */}
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{
                          width: 1,
                          padding: 1,
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography>{question?.cq}</Typography>
                        {/* <Typography color={'text.secondary'} variant="subtitle2">
                      {action?.explanation}
                    </Typography> */}
                      </Box>
                    </Box>
                    {readOnly ? null : (
                      <Box
                        sx={{
                          // background: '#d3e2fe',
                          padding: 2,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 1,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          minHeight: 80,
                          position: 'relative',
                        }}
                      >
                        {/* <HelpOutline
                                                            sx={{
                                                              cursor: 'pointer',
                                                            }}
                                                            onMouseEnter={(e) => {
                                                              setAnchorElDetails(
                                                                e.currentTarget,
                                                              );
                                                              setPopperDetails(
                                                                'This is a long explanation of why this question makes sense. It can be a multi line explanation. And the help box should have the height and width to handle that.',
                                                              );
                                                            }}
                                                            onMouseLeave={() => {
                                                              setAnchorElDetails(
                                                                null,
                                                              );
                                                              setPopperDetails(
                                                                null,
                                                              );
                                                            }}
                                                          />
                                                          <Popper
                                                            id="mouse-over-popover"
                                                            sx={{
                                                              pointerEvents: 'none',
                                                              zIndex: 1111111,
                                                            }}
                                                            open={Boolean(
                                                              anchorElDetails,
                                                            )}
                                                            anchorEl={
                                                              anchorElDetails
                                                            }
                                                            placement="left"
                                                            onClose={() => {
                                                              setAnchorElDetails(
                                                                null,
                                                              );
                                                            }}
                                                            // disableRestoreFocus
                                                          >
                                                            <Box
                                                              sx={{
                                                                border:
                                                                  '1px solid #d3d3d3',
                                                                borderRadius: 1,
                                                                padding: 1,
                                                                background: '#fff',
                                                                maxWidth: 400,
                                                                display: 'flex',
                                                                flexDirection:
                                                                  'column',
                                                                gap: 1,
                                                                // boxShadow: theme.shadows[1],
                                                              }}
                                                            >
                                                              <Typography
                                                                variant={'body1'}
                                                                color={
                                                                  'text.secondary'
                                                                }
                                                                sx={{
                                                                  padding: 1,
                                                                }}
                                                              >
                                                                {popperDetails ||
                                                                  ''}
                                                              </Typography>
                                                            </Box>
                                                          </Popper> */}
                        {/* <Tooltip arrow placement="top" title="Edit">
                                          <img
                                            src={'/dh-icons/edit.svg'}
                                            style={{
                                              cursor: 'pointer',
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                            }}
                                          />
                                        </Tooltip> */}
                        {/* <Tooltip
                      arrow
                      placement="top"
                      title={
                        selectedRisks?.includes(dr?.id) ? 'Unselect' : 'Select'
                      }
                    >
                      {selectedRisks?.includes(dr?.id) ? (
                        <img
                          src={'/dh-icons/pinned.svg'}
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {}}
                        />
                      ) : (
                        <img
                          src={'/dh-icons/unpinned.svg'}
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {}}
                        />
                      )}
                    </Tooltip> */}
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}
              {hints?.map((hint, index) => (
                <Box
                  key={hint}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: readOnly ? 'default' : 'pointer',
                    borderRadius: 2,
                    width: 1,
                    gap: 1,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    // setActiveNBA(action);
                  }}
                >
                  <img
                    src="/dh-icons/lighbulb.svg"
                    width={'24px'}
                    height={'24px'}
                  />
                  <Box
                    sx={{
                      background: '#F2F6FC',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 1,
                      flex: 1,
                      borderRadius: 2,
                      // minHeight: 80,
                      // border: dq?.pinned
                      //   ? '1px solid #beb9e7'
                      //   : 'none',
                      position: 'relative',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        flex: 1,
                        borderRadius: 2,
                        paddingLeft: 2,
                      }}
                    >
                      {/* <Typography
                                                        sx={{
                                                          // background: '#d3e2fe',
                                                          padding: 2,
                                                          borderTopLeftRadius: 8,
                                                          borderBottomLeftRadius: 8,
                                                          minHeight: 80,
                                                          minWidth: 80,
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          justifyContent: 'center',
                                                          fontWeight: 'bold',
                                                          cursor: 'pointer',
                                                        }}
                                                        onMouseEnter={(e) => {
                                                          setAnchorElDetails(
                                                            e.currentTarget,
                                                          );
                                                          setPopperDetails(
                                                            'This is a long explanation of why this question makes sense. It can be a multi line explanation. And the help box should have the height and width to handle that.',
                                                          );
                                                        }}
                                                        onMouseLeave={() => {
                                                          setAnchorElDetails(null);
                                                          setPopperDetails(null);
                                                        }}
                                                      >
                                                        {index < 9 ? 0 : ''}
                                                        {index + 1}
                                                      </Typography>
                                                      <Popper
                                                        id="mouse-over-popover"
                                                        sx={{
                                                          pointerEvents: 'none',
                                                          zIndex: 1111111,
                                                        }}
                                                        open={Boolean(
                                                          anchorElDetails,
                                                        )}
                                                        anchorEl={anchorElDetails}
                                                        placement="top"
                                                        onClose={() => {
                                                          setAnchorElDetails(null);
                                                        }}
                                                        // disableRestoreFocus
                                                      >
                                                        <Box
                                                          sx={{
                                                            border:
                                                              '1px solid #d3d3d3',
                                                            borderRadius: 1,
                                                            padding: 1,
                                                            background: '#fff',
                                                            maxWidth: 400,
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: 1,
                                                            // boxShadow: theme.shadows[1],
                                                          }}
                                                        >
                                                          <Typography
                                                            variant={'body1'}
                                                            color={'text.secondary'}
                                                            sx={{
                                                              padding: 1,
                                                            }}
                                                          >
                                                            {popperDetails || ''}
                                                          </Typography>
                                                        </Box>
                                                      </Popper> */}
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{
                          width: 1,
                          padding: 1,
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography>{hint}</Typography>
                        {/* <Typography color={'text.secondary'} variant="subtitle2">
                      {action?.explanation}
                    </Typography> */}
                      </Box>
                    </Box>
                    {readOnly ? null : (
                      <Box
                        sx={{
                          // background: '#d3e2fe',
                          padding: 2,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 1,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          minHeight: 80,
                          position: 'relative',
                        }}
                      >
                        {/* <HelpOutline
                                                            sx={{
                                                              cursor: 'pointer',
                                                            }}
                                                            onMouseEnter={(e) => {
                                                              setAnchorElDetails(
                                                                e.currentTarget,
                                                              );
                                                              setPopperDetails(
                                                                'This is a long explanation of why this question makes sense. It can be a multi line explanation. And the help box should have the height and width to handle that.',
                                                              );
                                                            }}
                                                            onMouseLeave={() => {
                                                              setAnchorElDetails(
                                                                null,
                                                              );
                                                              setPopperDetails(
                                                                null,
                                                              );
                                                            }}
                                                          />
                                                          <Popper
                                                            id="mouse-over-popover"
                                                            sx={{
                                                              pointerEvents: 'none',
                                                              zIndex: 1111111,
                                                            }}
                                                            open={Boolean(
                                                              anchorElDetails,
                                                            )}
                                                            anchorEl={
                                                              anchorElDetails
                                                            }
                                                            placement="left"
                                                            onClose={() => {
                                                              setAnchorElDetails(
                                                                null,
                                                              );
                                                            }}
                                                            // disableRestoreFocus
                                                          >
                                                            <Box
                                                              sx={{
                                                                border:
                                                                  '1px solid #d3d3d3',
                                                                borderRadius: 1,
                                                                padding: 1,
                                                                background: '#fff',
                                                                maxWidth: 400,
                                                                display: 'flex',
                                                                flexDirection:
                                                                  'column',
                                                                gap: 1,
                                                                // boxShadow: theme.shadows[1],
                                                              }}
                                                            >
                                                              <Typography
                                                                variant={'body1'}
                                                                color={
                                                                  'text.secondary'
                                                                }
                                                                sx={{
                                                                  padding: 1,
                                                                }}
                                                              >
                                                                {popperDetails ||
                                                                  ''}
                                                              </Typography>
                                                            </Box>
                                                          </Popper> */}
                        {/* <Tooltip arrow placement="top" title="Edit">
                                          <img
                                            src={'/dh-icons/edit.svg'}
                                            style={{
                                              cursor: 'pointer',
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                            }}
                                          />
                                        </Tooltip> */}
                        {/* <Tooltip
                      arrow
                      placement="top"
                      title={
                        selectedRisks?.includes(dr?.id) ? 'Unselect' : 'Select'
                      }
                    >
                      {selectedRisks?.includes(dr?.id) ? (
                        <img
                          src={'/dh-icons/pinned.svg'}
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {}}
                        />
                      ) : (
                        <img
                          src={'/dh-icons/unpinned.svg'}
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {}}
                        />
                      )}
                    </Tooltip> */}
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </>
        ) : (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              // width: 1,
              paddingBottom: 10,
              minHeight: 300,
              maxHeight: 350,
              padding: readOnly ? 2 : 1,
              // border: readOnly ? 'none' : '1px solid #d3d3d3',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 2,
            }}
          >
            <Typography color={'text.secondary'} mb={1}>
              Next Best Actions are not generated yet. Click below to generate.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

NextBestActions.propTypes = {
  readOnly: PropTypes.bool,
  activeArtifact: PropTypes.object,
};

export default NextBestActions;
