import {
  Box,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  FormControl,
  MenuItem,
  Popper,
  Select,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import LineGraph from 'components/LineGraph/LineGraph';
import parse from 'html-react-parser';
import {
  Add,
  Check,
  CheckCircle,
  Close,
  Sync,
  Troubleshoot,
  Warning,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import getAllDealNames from 'redux/actions/Common/getAllDealNames';
import crmHubspotSync from 'redux/actions/Common/crmHubspotSync';
import {
  artifactProcessingStatuses,
  artifactStatusMap,
  artifactTypesWithoutMedia,
} from 'data';
import { capitalizeText } from 'utils';
import generateNBA from 'redux/actions/Common/generateNBA';
import getArtifactRisks from 'redux/actions/Common/getArtifactRisks';

const ArtifactRisksSelectionList = ({
  viewType,
  readOnly,
  activeArtifact,
  successCallback,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const pathParams = useParams();
  const dispatch = useDispatch();
  const teamId = localStorage.getItem('teamId') || pathParams?.teamId;

  const { userDetails } = useSelector((state) => state.userDetails);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [selectedRisks, setSelectedRisks] = useState([]);
  const [generatingNbas, setGeneratingNbas] = useState(false);
  const [risks, setRisks] = useState([]);

  useEffect(() => {
    if (activeArtifact) {
      dispatch(
        getArtifactRisks(activeArtifact?.id, (data) => {
          console.log(data);
          if (readOnly) {
            setRisks(
              data?.recommendedRisks?.filter((risk) =>
                activeArtifact?.selectedRisks?.includes(risk?.id),
              ) || [],
            );
          } else {
            setRisks(data?.recommendedRisks || []);
          }
        }),
      );
    }
  }, [activeArtifact, readOnly]);

  useEffect(() => {
    setSelectedRisks(risks?.map((risk) => risk?.id));
  }, [risks]);

  console.log(risks);

  return (
    <Box
      sx={{ width: 1, padding: viewType === 'dealDetails' ? 2 : 0 }}
      display={'flex'}
      flexDirection={'column'}
      gap={2}
    >
      {readOnly ? null : (
        <Box
          display={'flex'}
          flexDirection={'column'}
          // gap={1}
        >
          <Typography fontWeight={'bold'} fontSize={20}>
            Select the appropriate risk to generate next best actions.
          </Typography>
          <Typography variant="caption" color={'text.secondary'}>
            You can alter the selection below.
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            // width: 1,
            minHeight:
              viewType === 'dealDetails'
                ? readOnly
                  ? 'calc(100vh - 380px)'
                  : 'calc(100vh - 380px)'
                : readOnly
                ? 'calc(100vh - 290px)'
                : 'calc(100vh - 260px)',
            maxHeight:
              viewType === 'dealDetails'
                ? readOnly
                  ? 'calc(100vh - 380px)'
                  : 'calc(100vh - 380px)'
                : readOnly
                ? 'calc(100vh - 290px)'
                : 'calc(100vh - 260px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            padding: readOnly ? 2 : 1,
            border: readOnly ? 'none' : '1px solid #d3d3d3',
            borderRadius: 2,
            paddingBottom: viewType === 'dealDetails' ? 10 : 10,
          }}
        >
          {risks?.map((dr, index) => (
            <Box
              key={index + 1}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: readOnly ? 'default' : 'pointer',
                borderRadius: 2,
                width: 1,
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (!readOnly) {
                  if (selectedRisks?.includes(dr?.id)) {
                    setSelectedRisks(
                      selectedRisks?.filter((id) => id !== dr?.id),
                    );
                  } else {
                    setSelectedRisks([...selectedRisks, dr?.id]);
                  }
                }
              }}
            >
              <Box
                sx={{
                  background: selectedRisks?.includes(dr?.id)
                    ? '#C2DBFF'
                    : '#F2F6FC',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 1,
                  flex: 1,
                  borderRadius: 2,
                  minHeight: 80,
                  // border: dq?.pinned
                  //   ? '1px solid #beb9e7'
                  //   : 'none',
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    flex: 1,
                    borderRadius: 2,
                    paddingLeft: 2,
                  }}
                >
                  {/* <Typography
                                                        sx={{
                                                          // background: '#d3e2fe',
                                                          padding: 2,
                                                          borderTopLeftRadius: 8,
                                                          borderBottomLeftRadius: 8,
                                                          minHeight: 80,
                                                          minWidth: 80,
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          justifyContent: 'center',
                                                          fontWeight: 'bold',
                                                          cursor: 'pointer',
                                                        }}
                                                        onMouseEnter={(e) => {
                                                          setAnchorElDetails(
                                                            e.currentTarget,
                                                          );
                                                          setPopperDetails(
                                                            'This is a long explanation of why this question makes sense. It can be a multi line explanation. And the help box should have the height and width to handle that.',
                                                          );
                                                        }}
                                                        onMouseLeave={() => {
                                                          setAnchorElDetails(null);
                                                          setPopperDetails(null);
                                                        }}
                                                      >
                                                        {index < 9 ? 0 : ''}
                                                        {index + 1}
                                                      </Typography>
                                                      <Popper
                                                        id="mouse-over-popover"
                                                        sx={{
                                                          pointerEvents: 'none',
                                                          zIndex: 1111111,
                                                        }}
                                                        open={Boolean(
                                                          anchorElDetails,
                                                        )}
                                                        anchorEl={anchorElDetails}
                                                        placement="top"
                                                        onClose={() => {
                                                          setAnchorElDetails(null);
                                                        }}
                                                        // disableRestoreFocus
                                                      >
                                                        <Box
                                                          sx={{
                                                            border:
                                                              '1px solid #d3d3d3',
                                                            borderRadius: 1,
                                                            padding: 1,
                                                            background: '#fff',
                                                            maxWidth: 400,
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: 1,
                                                            // boxShadow: theme.shadows[1],
                                                          }}
                                                        >
                                                          <Typography
                                                            variant={'body1'}
                                                            color={'text.secondary'}
                                                            sx={{
                                                              padding: 1,
                                                            }}
                                                          >
                                                            {popperDetails || ''}
                                                          </Typography>
                                                        </Box>
                                                      </Popper> */}
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    sx={{
                      width: 1,
                      padding: 1,
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography>{dr?.riskTitle}</Typography>
                    <Typography color={'text.secondary'} variant="subtitle2">
                      {dr?.explanation}
                    </Typography>
                  </Box>
                </Box>
                {readOnly ? null : (
                  <Box
                    sx={{
                      // background: '#d3e2fe',
                      padding: 2,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 1,
                      borderTopRightRadius: 8,
                      borderBottomRightRadius: 8,
                      minHeight: 80,
                      position: 'relative',
                    }}
                  >
                    {/* <HelpOutline
                                                            sx={{
                                                              cursor: 'pointer',
                                                            }}
                                                            onMouseEnter={(e) => {
                                                              setAnchorElDetails(
                                                                e.currentTarget,
                                                              );
                                                              setPopperDetails(
                                                                'This is a long explanation of why this question makes sense. It can be a multi line explanation. And the help box should have the height and width to handle that.',
                                                              );
                                                            }}
                                                            onMouseLeave={() => {
                                                              setAnchorElDetails(
                                                                null,
                                                              );
                                                              setPopperDetails(
                                                                null,
                                                              );
                                                            }}
                                                          />
                                                          <Popper
                                                            id="mouse-over-popover"
                                                            sx={{
                                                              pointerEvents: 'none',
                                                              zIndex: 1111111,
                                                            }}
                                                            open={Boolean(
                                                              anchorElDetails,
                                                            )}
                                                            anchorEl={
                                                              anchorElDetails
                                                            }
                                                            placement="left"
                                                            onClose={() => {
                                                              setAnchorElDetails(
                                                                null,
                                                              );
                                                            }}
                                                            // disableRestoreFocus
                                                          >
                                                            <Box
                                                              sx={{
                                                                border:
                                                                  '1px solid #d3d3d3',
                                                                borderRadius: 1,
                                                                padding: 1,
                                                                background: '#fff',
                                                                maxWidth: 400,
                                                                display: 'flex',
                                                                flexDirection:
                                                                  'column',
                                                                gap: 1,
                                                                // boxShadow: theme.shadows[1],
                                                              }}
                                                            >
                                                              <Typography
                                                                variant={'body1'}
                                                                color={
                                                                  'text.secondary'
                                                                }
                                                                sx={{
                                                                  padding: 1,
                                                                }}
                                                              >
                                                                {popperDetails ||
                                                                  ''}
                                                              </Typography>
                                                            </Box>
                                                          </Popper> */}
                    {/* <Tooltip arrow placement="top" title="Edit">
                                          <img
                                            src={'/dh-icons/edit.svg'}
                                            style={{
                                              cursor: 'pointer',
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                            }}
                                          />
                                        </Tooltip> */}
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        selectedRisks?.includes(dr?.id) ? 'Unselect' : 'Select'
                      }
                    >
                      {selectedRisks?.includes(dr?.id) ? (
                        <img
                          src={'/dh-icons/pinned.svg'}
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {}}
                        />
                      ) : (
                        <img
                          src={'/dh-icons/unpinned.svg'}
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {}}
                        />
                      )}
                    </Tooltip>
                  </Box>
                )}
              </Box>
            </Box>
          ))}
        </Box>
        {readOnly ? null : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: 1,
              background: '#fff',
              position: 'absolute',
              bottom: 0,
              left: 0,
              padding: 2,
              borderTop: '1px solid #d3d3d3',
            }}
          >
            <Button
              variant="contained"
              size="small"
              disabled={
                generatingNbas || (selectedRisks && selectedRisks?.length === 0)
              }
              startIcon={generatingNbas ? <CircularProgress size={20} /> : null}
              onClick={() => {
                setGeneratingNbas(true);
                dispatch(
                  generateNBA(
                    activeArtifact?.id,
                    {
                      selectedRisks,
                    },
                    (data) => {
                      console.log(data);
                      setGeneratingNbas(false);
                      successCallback(selectedRisks, data);
                    },
                  ),
                );
              }}
            >
              Generate Next Best Actions
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

ArtifactRisksSelectionList.propTypes = {
  viewType: PropTypes.string,
  readOnly: PropTypes.bool,
  activeArtifact: PropTypes.object,
  successCallback: PropTypes.func,
};

export default ArtifactRisksSelectionList;
